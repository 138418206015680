import TableFunctions from './api/TableFunctions.js'
import ReactTable from "react-table-6"
import AlertDialog from "../user/Dialog.js"
import Translation from '../user/Translation'
import UserProfile from '../user/UserProfile'
import GetJsonFormPHP from "./api/GetJsonFormPHP"
import {setTableFontSize} from './api/imports.js'
import Info from "./api/Info"
import Select from 'react-select'
import './styles/react-table.css'
import imgFalse from './img/false.png'
import imgTrue from './img/true.gif'
import imgAttention from './img/attention.png'

function t(field) { return Translation.get(field) }

class RobotList extends TableFunctions {
  constructor() {
    super();
    this.state={
      geturl: "/robots/get_robots.php",
      saveurl: "/robots/set_robots.php",
      setTableUrl: "/users/change_tablesize.php",
      orgData: [], data: [], empty: null, selected: null, // Table data
      customers: null, robottypes: null, // Select input options
      showHelp: false, showHelpId: null, // Info popup
      confirmVisible: false, title: null, message: null, choise: true, yesNo: [t('no'), t('yes')],  // OK popup and Error Messages
      robotFilter: 0, customerFilter: 0, statusFilter: false, customerSelect: null, typeSelect: null,  // Filter Options
      fontSize: setTableFontSize(),
      fieldWidths: null, tablePageSize: 0, // Show table fields
    }
  }

  reload() {
    this.componentDidMount()
  }
  componentDidMount() {
    GetJsonFormPHP(this.state.geturl).then((result) => {
      this.setState({ empty: result.empty, })
      if (result.data === null) result.data = [this.state.empty]
      let pageSize = 25
      if (result.fieldwidth && result.fieldwidth._pageSize) pageSize = result.fieldwidth._pageSize
      this.setState({
        orgData: result.data,
        data: result.data,
        customers: result.customers,
        robottypes: result.robottypes,
        customerSelect: result.customerselect,
        typeSelect: result.typeselect,
        feederSelect: result.feederselect,
        fieldWidths: result.fieldwidth,
        tablePageSize: pageSize,
      })
    })
  }
  radioboxCell(initialValue, id, row, options) {
    const inputChange = value => { this.handleInputChange(row._index, id, value) }
    if (this.state.selected === row._index) {
      return (
        <div>
        <label><input type="radio" name={id} value="1" className="checkbox" checked={row.enabled === "1"} onChange={inputChange} />{options[1]}</label><br />
        <label><input type="radio" name={id} value="0" className="checkbox" checked={row.enabled === "0"} onChange={inputChange} />{options[0]}</label>
        </div>
      )
    }
    else {
      if (initialValue === "1") return (<div width="100%" align="center"><img src={imgTrue} alt={t("enabled")} title={t("enabled")} /></div>)
      else return (<div width="100%" align="center"><img src={imgFalse} alt={t("disabled")} title={t("disabled")} /></div>)
    }
  }
  setfilter(value, newstate=true) {
    let filter1 = this.state.customerFilter
    let filterRobot = this.state.robotFilter
    let filter3 = this.state.statusFilter
    if (value.name === "filter_customers") filter1 = value.value
    if (value.name === "filter_robots") filterRobot = value.value
    if (value.name === "filter_status") filter3 = !this.state.statusFilter
    let  res = this.state.orgData
    let a =[]
    if (filter3) {
      // eslint-disable-next-line array-callback-return
      res.map((t,k) => { if (t.last_update !== null || t.id === -1) a = [...a, t] })
        res = a
    }
    //else res = this.state.orgData
    a = []
    if (filter1 > 0) {
      // eslint-disable-next-line array-callback-return
      res.map((t,k) => { if (t.customer_id === parseInt(filter1) || t.id === -1 || t.trader_id === parseInt(filter1) || t.importer_id === parseInt(filter1))  a = [...a, t] })
      res = a
    }
    a = []
    if (filterRobot > 0) {
      // eslint-disable-next-line array-callback-return
      res.map((t,k) => { if (t.robottype_id ===  parseInt(filterRobot) || t.id === -1) a = [...a, t] })
      res = a
    }
    //else a2 = a
    //console.log(a1)
    if (newstate) this.setState({
      data: res,
      robotFilter: filterRobot,
      customerFilter: filter1,
      statusFilter: filter3,
    })
    //console.log(filter3, this.state.statusFilter)
    return res
  }
  createColumns() {
    const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))*this.state.fontSize
    const suRight = UserProfile.checkRight("superuser")
    const supportRight = UserProfile.checkRight("support")
    const traderRight = UserProfile.checkRight("trader")
    const subtraderRight =  UserProfile.checkRight("addcust") || UserProfile.checkRight("service")
    const userRight = UserProfile.checkRight("custuser")
    const w = this.state.fieldWidths
    //console.log(w)
    const columns = [
      { accessor: 'id',
        show: supportRight,
        Header: 'ID',
        width: (w && w['id'] ? w['id'] : 2)*em,
        getProps: (state, rowInfo) => ({ style: { textAlign: 'right', } }),
      },
      { accessor: 'online', show: false,},
      { accessor: 'trader_id', show: false,},
      { accessor: 'importer_id', show: false,},
      this.addSelectCell("customer", "customer_id", this.state.customers, (w && w['customer_id'] ? w['customer_id'] : 0), subtraderRight, null, null, null, supportRight),
      this.addSelectCell("robottype_name", "robottype_id", this.state.robottypes, (w && w['robottype_id'] ? w['robottype_id'] : 0), true, null, null, false, suRight, false, false, null, false, true),
      //this.addSelectCell("robottype_multi", "multi", this.state.robottypes, 0, true, null, null, false, suRight),
      this.addSelectCell("robottype_multi", "multi", this.state.yesNo, (w && w['multi'] ? w['multi'] : 3)*this.state.fontSize, this.state.showfields ? this.state.showfields['screw_no_return'] : true, "center", null, false, traderRight, false, false, null, true),
      //this.addTextCell('robot_id_name', 'id_name', 0, (w && w['id_name'] ? w['id_name'] : 12)*this.state.fontSize, supportRight, "", null),
      this.addTextCell('ip', 'ip', 4, (w && w['ip'] ? w['ip'] : 7), suRight, "", 'right'),
      //this.addTextCell('ip_wlan', 'ip_wlan', 2, 6.5, suRight, "", 'right', this.getColor('cellAdmin')),
      this.addTextCell('robotname', 'name', 0, (w && w['name'] ? w['name'] : 0)),
      this.addTextCell('robots_serial_number', 'serial_number', 0, (w && w['serial_number'] ? w['serial_number'] : 12)*this.state.fontSize, supportRight, "", null),
      //this.addTextCell('stable', 'stable', 0, 7),
      //this.addDateCell('order_date', 'order_date', false, 8, true, "right"),
      this.addDateCell('robots_ibn_date', 'deliver_date', false, (w && w['deliver_date'] ? w['deliver_date'] : 8)*this.state.fontSize, true, "right", null, null, traderRight),
      this.addDateCell('last_service', 'last_service_date', false, (w && w['last_service_date'] ? w['last_service_date'] : 8)*this.state.fontSize, true, "right", null, null, traderRight),
      //this.addDateCell('next_service', 'next_service_date', false, 8, true, "right", null, null, false),
      //this.addTextCell('§' + t('last_service') + " [h]", 'last_service_h', 1, 6, true, "", null, null, null, traderRight),
      //this.addTextCell('robot_operation_hours', 'operation_hours', 1, 6, true, "", null, null, null, traderRight),
      this.addTextCell('robots_firmware_version', 'firmware_version', 0, (w && w['firmware_version'] ? w['firmware_version'] : 5)*this.state.fontSize, supportRight, "", "center"),
      this.addTextAreaCell('info', 'info', w && w['info'] ? w['info'] : 0, 0),
      //this.addCheckboxCell('robot_inactive', 'inactive', 4, traderRight, )
      //this.addSelectCell("robottype_feeder", "feeder_id", this.state.feederSelect, 0, true, null, null, false, true),
      {
        Header: t("robottype_feeder"),
        accessor: "feeder_id",
        show: supportRight,
        Cell: ({value: initialValue, column: { id }, row}) => {
          let edit = false
          if ((row.robottype_id === 1 || row.robottype_id === 3 || row.robottype_id === 4) && (supportRight || UserProfile.checkSupport())) edit = true   // Aranom, Aramis I + II
          return this.selectCell(id , row, initialValue, this.state.feederSelect, true, edit, null ? initialValue : null, null, false)
        },
        width: (w && w['feeder_id'] ? w['feeder_id'] : 4)*em,
        getProps: (state, rowInfo) => ({ style: { textAlign: null, backgroundColor: rowInfo.row.status === 5 || rowInfo.row.status === 6 ? this.getStatusBackColor(rowInfo.row.status) : null } }),
        filterable: false,
      },
      this.addSelectCell("robot_active", "active", this.state.yesNo, 4, this.state.showfields ? this.state.showfields['active'] : true, "center", null, false, traderRight, false, false, null, true),
      //this.addSelectCell("robots_new", "new", this.state.yesNo, 4, supportRight, "center", null, false, false, false, false, null, true, true),
      this.addTextCell('robots_last_status', 'last_update', 0, (w && w['last_update'] ? w['last_update'] : 5)*this.state.fontSize, supportRight, "", "center", null, null, false),
      { accessor: 'edit',
        Header: t('table_edit'),
        width: 8.2*em,
        filterable: false,
        resizable: false,
        sortable: false,
        Cell: ({value: initialValue, column: { id }, row,}) => {
          let img = null
          let text = ""
          if (row.customer_id !== 1 && (row.robottype_id === 1 || row.robottype_id === 3 || row.robottype_id === 4) && row.feeder_id <= 0) {
            img = imgAttention
            text = "*Futterküche fehlt!"
          }
          return (
            <div>
              {img !== null  ? <img src={img} alt={text} title={text}/> : null}
              {this.edit(row, supportRight, suRight, userRight)}
            </div>
          )
        },
      }
    ]
    return columns
  }

  render() {
    //console.log("render", this.state)
    const columns = this.createColumns()
    const supportRight = UserProfile.checkRight("support")
    const traderRight = UserProfile.checkRight("trader")
    const userRight = UserProfile.checkRight("custuser")
    const selectChange  = value => { this.setfilter(value) }
    const changeColumnsWidth = (width, name) => {
      if (name.length<100) {
        const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))
        GetJsonFormPHP(this.state.setTableUrl + "?table=robot&col=" + name + "&width=" + width + "&em=" + em).then((result) => {
        })
      }
    }
    const dot = (color = 'transparent') => ({
      alignItems: 'center',
      display: 'flex',
      ':before': {
        backgroundColor: color,
        borderRadius: 10,
        content: '" "',
        display: 'block',
        marginRight: 8,
        height: 10,
        width: 10,
      },
    })
    const colourStyles = {
      control: (styles) => ({ ...styles, height: 28, minHeight: 28, }),
      menuList: (provided, state) => ({ ...provided, paddingTop: 0, }),
      menu: base => ({ ...base, marginTop: 0, }),
      container: (base) => ({ ...base, display:'inline-block', }),
      indicatorContainer: (styles) => ({ ...styles, padding: 0, margin: 0, }),
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        const color = data.color
        return {
          ...styles,
          backgroundColor: isDisabled ? undefined
            : isFocused ? color //  color.alpha(0.1).css()
            : isSelected ? color //data.color
            : undefined,
          color: isDisabled ? '#ccc'
            : isFocused  ? 'white'
            : isSelected ? 'white'
            : data.color,
          cursor: isDisabled ? 'not-allowed' : 'default',
          ':active': {
            ...styles[':active'],
            backgroundColor: !isDisabled ? isSelected ? data.color : color  //color.alpha(0.3).css()
              : undefined,
          },
          paddingTop: 2,
          paddingBottom: 2,
        };
      },
      input: (styles) => ({ ...styles, ...dot() }),
      //placeholder: (styles) => ({ ...styles, ...dot('#ccc') }),
      singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
    }
    let data = this.state.data
    if (this.state.robotFilter)  data = this.setfilter({name: "filter_robots", value: this.state.robotFilter}, false)
    if (this.state.customerFilter) data = this.setfilter({name: "filter_customers", value: this.state.customerFilter}, false)
    return (
      <div className={supportRight ? "": "table_100"}>
        <div className="table_buttons">
        { traderRight ? <button onClick={() => {this.insertRow(); }}>{t("addline")}</button> : null }
          { traderRight && this.state.orgData.length > 2 ? <span>
            {t('filter_customer')}:&nbsp;
            <Select
              options={this.state.customerSelect}
              styles={colourStyles}
              isSearchable={true}
              isClearable={true}
              className="chartselect"
              onChange={(value) => {selectChange({name: "filter_customers", value: value !== null ? value.value : 0})}}
              placeholder={t('select')}
            />
            {t('filter_robots')}:&nbsp;
            <Select
              options={this.state.typeSelect}
              styles={colourStyles}
              isSearchable={true}
              isClearable={true}
              className="chartselect"
              onChange={(value) => {selectChange({name: "filter_robots", value: value !== null ? value.value : 0})}}
              placeholder={t('select')}
            />
            <input id="remove_inactive" type="checkbox" defaultChecked={this.state.statusFilter}  onChange={() => {selectChange({name: "filter_status", value: null})}}/>{t("filter_status")}
          </span> : null }
          <div className="right_side"><img id="info" src={require("./img/info.png")} alt="Info" onClick={() => { this.setState({ showHelp: true, showHelpId: "help_robots_table",}) } } onMouseOver={(e) => {e.currentTarget.src=require("./img/info_mouseover.png")}} onMouseOut={(e) => {e.currentTarget.src=require("./img/info.png")}} /></div>
        </div>
        {this.state.tablePageSize > 0 ?
        <div style={{fontSize: this.state.fontSize+"em"}}>
        <ReactTable
          data={data}
          columns={columns}
          minRows = {0}
          previousText= {'<'}
          nextText= {'>'}
          showPageJump= {true}
          defaultPageSize = {this.state.tablePageSize}
          pageSizeOptions = {[10, 25, 50, 100]}
          useFlexLayout = {true}
          getTrProps={(state, rowInfo, column, instance) => {
            return {
              onDoubleClick: (e, t) => {
                if (rowInfo.index !== this.state.selected && userRight) { this.setState({ selected: rowInfo.index, }) }
              },
              style: {
                background: rowInfo && rowInfo.index === this.state.selected ? 'rgba(234, 234, 255, 1)' : rowInfo.row.active === 1 ? this.getRobotColor(rowInfo.row.robottype_id) : null,
                color: rowInfo && rowInfo.index === this.state.selected ? this.getColor('selected') : rowInfo.row.customer_id === 1 ? this.getColor('production') : rowInfo.row.customer_id === 2 ? "darkgrey" : rowInfo.row.active === 1 ? rowInfo.row.online === 0 && rowInfo.row.last_update ? '#DD0000' : 'black' : "darkgrey"
              }
            }
          }}
          getTheadTrProps={(state, column, instance) => {
            return {
              onClick: (e) => {if (!UserProfile.isSafari()) changeColumnsWidth(e.target.offsetParent.style.width, e.target.offsetParent.innerText); },
            }
          }}
          getProps = {(state, column, instance) => {
            return {
              onChange:  (e) => { if (e.target.type !== "number" && e.target.type !== "text" && e.target.name === "") changeColumnsWidth(e.target.value, "_pageSize") },
            }
          }}
        />
        </div>
        :null }
        {this.state.showHelp && <Info id={this.state.showHelpId} closeDiv={() => { this.setState({ showHelp: false, }) }} reset={() => { this.setState({ showHelpId: null, }) }}/>}
        <AlertDialog  openDialog = {this.state.confirmVisible} title = {this.state.title} message = {this.state.message} choise = {this.state.choise} callback = {this.handleDelete}/>
      </div>
    )
  }
}
export default RobotList;
