import React, { Component } from 'react'
import TableFunctions from './api/TableFunctions.js'
import ReactTable from "react-table-6"
import Draggable from "react-draggable"
import AlertDialog from "../user/Dialog.js"
import TextField from '@mui/material/TextField';
import dateFormat from 'dateformat'
import Translation from '../user/Translation'
import Select from 'react-select'
import Info from "./api/Info"
import UserProfile from '../user/UserProfile'
import GetJsonFormPHP, { PostJsonFormPHP } from "./api/GetJsonFormPHP"
import {setTableFontSize} from './api/imports.js'

import './styles/tablestyles.css'
import './styles/react-table.css'
import imgClose from './img/close.png'
import imgActivate from './img/activate_send.png'
import imgSupport from './img/support.png'
import imgBug from './img/bug_gold.png'
import imgFalse from './img/false.png'
import imgFalse2 from './img/false2.png'
import imgTrue from './img/true.gif'
import imgImporter from './img/importer.png'

function t(field) { return Translation.get(field) }

class CustomerSettings extends Component {
  constructor() {
    super()
    this.state={
      saveurl: "/users/set_customer_api.php",
      loadNewData: true,
      herdeAnimals: false, herdeMilk: false, lkvMilk: false,
    }
  }

  static getDerivedStateFromProps(props, state) {
    let lkvMilk = false
    let herdeAnimals = false
    let herdeMilk = false
    //console.log("set state", props, state)
    if (props.apiData) {
      if (props.apiData.lkvMilk) if (props.apiData.lkvMilk.active === 1) lkvMilk = true
      if (props.apiData.herdeAnimals) if (props.apiData.herdeAnimals.active === 1) herdeAnimals = true
      if (props.apiData.herdeMilk) if (props.apiData.herdeMilk.active === 1) herdeMilk = true
    }
    if (props.apiData && state.loadNewData) return {
      herdeAnimals: herdeAnimals,
      herdeMilk: herdeMilk,
      lkvMilk: lkvMilk,
      loadNewData: false,
    }
    else return {}
  }

  saveApiSettings() {
    let data = {}
    data.herdeAnimals = this.state.herdeAnimals
    data.herdeMilk = this.state.herdeMilk
    data.lkvMilk = this.state.lkvMilk
    //console.log(data)
    PostJsonFormPHP(this.state.saveurl, data).then((result) => {
      //console.log(result)
      //this.props.closeDiv(result.error)
    })

  }
  render() {
    const inputChange = value => {
      switch(value.target.name) {
        case "lkv_milk_import":
          this.setState({ lkvMilk: !this.state.lkvMilk })
          break
        case "herde_milk_import":
          this.setState({ herdeMilk: !this.state.herdeMilk })
          break
        case "herde_animal_import":
          this.setState({ herdeAnimals: !this.state.herdeAnimals })
          break
        default: break
      }
    }
    //console.log(this.state)
    return (
      <div align="center">
        <br />
        <div className="edittable fixtable">
          <table cellSpacing="1" cellPadding="3">
            <tbody>
              <tr><td width="5%"><input type="checkbox" name={"lkv_milk_import"} value="1" className="checkbox" checked={this.state.lkvMilk} onChange={inputChange} /></td><td width="95%">*LKV Milchdatenimport</td></tr>
              <tr><td><input type="checkbox" name={"herde_milk_import"} value="1" className="checkbox" checked={this.state.herdeMilk} onChange={inputChange} /></td><td>*HERDE Milchdatenimnport</td></tr>
              <tr><td><input type="checkbox" name={"herde_animal_import"} value="1" className="checkbox" checked={this.state.herdeAnimals} onChange={inputChange} /></td><td>*HERDE Viehzahlenimport</td></tr>
              <tr><td colSpan="2" align="center">
                <div className="table_buttons">
                  <button onClick={() => {this.saveApiSettings()}}>{t("customer_add_import")}</button>
                </div>
              </td></tr>
            </tbody>
          </table>
        </div>
      </div>
    )
  }

}

class AddCustomer extends Component {
  constructor() {
    super();
    let aboDay = new Date()
    aboDay.setDate(aboDay.getDate()+92)
    this.state={
      saveurl: "/users/add_customer.php",
      name: "",
      address: "",
      contact: "",
      email: "",
      countries: null, languages: null,
      country: "AT", language: "de",
      create_user: true,
      login: "",
      abo: dateFormat(aboDay, "yyyy-mm-01"),
    }
    this.handleClose = this.handleClose.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }
  static getDerivedStateFromProps(props, state) {
    return {
      countries: props.countries,
      languages: props.languages,
    }
  }
  handleClose() {
    this.props.closeDiv();
  }
  handleSubmit() {
    let data = {}
    data.name = this.state.name
    data.address = this.state.address
    data.contact = this.state.contact
    data.email = this.state.email
    data.country = this.state.country
    data.language = this.state.language
    data.create_user = this.state.create_user
    data.login = this.state.login
    data.abo = this.state.abo
    //console.log(data)
    PostJsonFormPHP(this.state.saveurl, data).then((result) => {
      //console.log(result)
      this.props.closeDiv(result.error)
    })
  }

  render () {
    //console.log(this.state.countries)
    const changeValue = value => {
      switch(value.target.name) {
        case "name":  this.setState({ name: value.target.value, }); break;
        case "address":  this.setState({ address: value.target.value, }); break;
        case "contact":  this.setState({ contact: value.target.value, }); break;
        case "email":  this.setState({ email: value.target.value, }); break;
        case "country":  this.setState({ country: value.target.value, }); break;
        case "language":  this.setState({ language: value.target.value, }); break;
        case "create_user":  this.setState({ create_user: !this.state.create_user, }); break;
        case "login":  this.setState({ login: value.target.value, }); break;
        case "abo":  this.setState({ abo: value.target.value, }); break;
        default: break;
      }
    }
    return (
      <Draggable handle=".handle">
        <div className="edittable">
          <div className="handle"><div className="header">{t("add_new_customer")}<img src={imgClose} alt={t('close')} className="close"  onClick={this.handleClose}/></div></div>
          <div>
            <table cellSpacing="1" cellPadding="3">
              <tbody>
                <tr><td>{t("add_customer_name")}</td><td colSpan="3"><input type="text" name="name" value={this.state.name} onChange={changeValue} className="longinput"/></td></tr>
                <tr>
                  <td>{t("add_customer_country")}</td><td colSpan="3"><select name="country" value={this.state.country} onChange={changeValue}>
                    { Object.entries(this.state.countries).map((t,k) => <option key={k} value={t[0]}>{t[1]}</option>) }
                  </select></td>
                </tr>
                <tr><td>{t("add_customer_address")}</td><td colSpan="3"><input type="text" name="address" value={this.state.address} onChange={changeValue} className="longinput"/></td></tr>
                <tr><td>{t("add_customer_contact")}</td><td colSpan="3"><input type="text" name="contact" value={this.state.contact} onChange={changeValue} className="longinput"/></td></tr>
                <tr><td>{t("add_customer_user")}</td><td colSpan="3"><input type="checkbox" name="create_user" value="1" checked={this.state.create_user} onChange={changeValue} className="checkbox" /></td></tr>
                <tr>
                  <td>{t("add_customer_loginname")}</td><td><input type="text" name="login" value={this.state.login} onChange={changeValue} className="input12em" disabled={!this.state.create_user} /></td>
                  <td>{t("add_customer_language")}</td><td><select name="language" value={this.state.language} onChange={changeValue} disabled={!this.state.create_user}>
                    { Object.entries(this.state.languages).map((t,k) => <option key={k} value={t[0]}>{t[1]}</option>) }
                  </select></td>
                </tr>
                <tr><td>{t("add_customer_email")}</td><td colSpan="3"><input type="text" name="email" value={this.state.email} onChange={changeValue} className="longinput"/></td></tr>
                <tr><td>{t("add_customer_abo")}</td><td colSpan="3"><div><TextField name="abo" type="date" value={this.state.abo} InputLabelProps={{ shrink: true,}} onChange={changeValue} className="datefield"/></div></td></tr>
              </tbody>
            </table>
            <div className="table_buttons" align="center"><button onClick={() => {this.handleSubmit();console.log("click")}}>{t("add_new_customer")}</button></div>
          </div>
        </div>
      </Draggable>
    )
  }
}


class Customers extends TableFunctions {
  constructor() {
    super()
    let fontSize = 1
    if (UserProfile.checkRight("trader")) fontSize = 0.9
    this.state={
      geturl: "/users/get_customers.php",
      saveurl: "/users/set_customer.php",
      activationurl: "/users/activate_customer.php",
      setCustomerUrl: "/users/set_customer_id.php",
      setTableUrl: "/users/change_tablesize.php",
      orgData: [], data: [], empty: null, selected: null,  // Table data
      customers: null, trader: null, services: null, languages: null, countries: null,  // Select input options
      confirmVisible: false, title: null, message: null, choise: true,  // OK popup and Error Messages
      fieldWidths: null, manageFields: false, tablePageSize: 0, // Show table fields
      filterTrader: 0, filterName: "", customerSelect: null, // Filter Options
      showHideEdit: false,
      apiData: null,
      showHelp: false, showHelpId: null, // Info popup
      fontSize: setTableFontSize(fontSize),
    }
    this.closeNewCustomer = this.closeNewCustomer.bind(this)
  }

  reload() {
    this.componentDidMount()
  }
  componentDidMount() {
    GetJsonFormPHP(this.state.geturl).then((result) => {
      this.setState({ empty: result.empty, })
      if (result.data === null) result.data = [this.state.empty]
      let pageSize = 25
      if (result.fieldwidth && result.fieldwidth._pageSize) pageSize = result.fieldwidth._pageSize
      //let service = [{ '1': "Hetwin",}]
      //service[1] = "Hetwin"
      //service = [...service, ...result.trader]
      //console.log(service, result.trader)
      this.setState({
        orgData: result.data,
        data: result.data,
        customers: result.customers,
        trader: result.trader,
        service: result.service,
        countries: result.countries,
        languages: result.languages,
        fieldWidths: result.fieldwidth,
        tablePageSize: pageSize,
        apiData: result.apis,
      })
    })
    this.selectedCheckboxes = new Set();
    //if (UserProfile.checkRight("trader")) this.setState({ fontSize: this.state.fontSize*0.8, })
  }
  /*radioboxCell(initialValue, id, row, options) {
    const inputChange = value => { this.handleInputChange(row._index, id, value) }
    if (this.state.selected === row._index) {
      return (
        <div>
        <label><input type="radio" name={id} value="1" className="checkbox" checked={row.enabled === 1} onChange={inputChange} />{options[1]}</label><br />
        <label><input type="radio" name={id} value="0" className="checkbox" checked={row.enabled === 0} onChange={inputChange} />{options[0]}</label>
        </div>
      )
    }
    else {
      if (initialValue === 1) return (<div width="100%" align="center"><img src={imgTrue} alt={t("enabled")} title={t("enabled")} /></div>)
      else return (<div width="100%" align="center"><img src={imgFalse} alt={t("disabled")} title={t("disabled")} /></div>)
    }
  }*/
  closeNewCustomer(error) {
    if (error) {
      this.setState({
        confirmVisible: true,
        title: t("error"),
        message: t(error),
        choise: false,
        selected: null,
      })
    }
    else {
      this.setState({
      //selected: null,
      showHideEdit: false,
      })
      this.componentDidMount()
    }
  }
  radioboxCell(initialValue, id, row, value, options) {
    //console.log(id, options, value, row.id)
    const inputChange = value => { this.handleInputChange(row._index, id, value) }
    if (this.state.selected === row._index) {
      return (
        <div>
          {options[2] ? <label><input type="radio" name={id} value="2" className="checkbox" checked={parseInt(value) === 2} onChange={inputChange} />{options[2]}<br /></label> : null}
          <label><input type="radio" name={id} value="1" className="checkbox" checked={parseInt(value) === 1} onChange={inputChange} />{options[1]}</label><br />
          <label><input type="radio" name={id} value="0" className="checkbox" checked={parseInt(value) === 0} onChange={inputChange} />{options[0]}</label>
        </div>
      )
    }
    else {
      if (initialValue === 1) return (<div width="100%" align="center"><img src={imgTrue} alt={t("enabled")} title={t("enabled")} /></div>)
      else {
        if (initialValue === 0) {
          if (id === "is_trader") return null
            else return (<div width="100%" align="center"><img src={imgFalse2} alt={t("disabled")} title={t("disabled")} /></div>)
        }
        if (initialValue === 2 || initialValue === 10) return (<div width="100%" align="center"><img src={imgImporter} alt={t("importer")} title={t("importer")} /></div>)
        else return (<div width="100%" align="center"><img src={imgFalse} alt={t("deleted")} title={t("deleted")} /></div>)
      }
    }
  }
  activate1(id) {
    //console.log(this.state.activationurl + "?id=" + id)
    PostJsonFormPHP(this.state.activationurl + "?id=" + id).then((result) => {
      console.log(result)
      if (result.error) {
        this.setState({
          confirmVisible: true,
          title: t("error"),
          message: t(result.error),
          choise: false,
        })
      }
    })
    this.componentDidMount()
  }
  setCustomer(id) {
    //console.log("change cust", id)
    GetJsonFormPHP(this.state.setCustomerUrl + '?id=' + id).then((result) => {
      if (result.succeed) {
        UserProfile.setProfile(result, this.state.login)
        window.location.reload(false)
      }
    })
  }

  createColumns() {
    const suRight = UserProfile.checkRight("superuser")
    const supportRight = UserProfile.checkRight("support")
    const traderRight = UserProfile.checkRight("trader")
    const subtraderRight = UserProfile.checkRight("addcust")
    const denyServiceRight = !UserProfile.checkRight("service") || subtraderRight
    const fontSize = this.state.fontSize
    //console.log(denyServicetRight)
    const w = this.state.fieldWidths
    const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))*fontSize
    const columns = [
      { accessor: 'id',
        show: suRight,
        width:  2.5*em,
      },
      { accessor: 'customer_id', show: false,},
      { accessor: 'show',
        show: subtraderRight,
        Header: "",
        width: 5.8*em,
        filterable: false,
        resizable: false,
        sortable: false,
        Cell: ({value: initialValue, column: { id }, row,}) => {
          const userID = parseInt(UserProfile.getId())
          const customerChange = value => { console.log("sende Customer", row.id);  this.setCustomer(row.id) }
          return (
            <div align="center">
              {UserProfile.getUserLevel() <= 4 && (row.is_trader === 0 || UserProfile.getUserLevel() <= 2) && row.id !== 1 ? <img src={imgSupport} alt="" title={t("customer_change")} onClick={customerChange}/> : null}
              {(userID === 1 || userID === 3 || userID === 7 || userID === 30 || userID === 40) && row.is_trader === 0 ? <a href={"https://connect.hetwin.at/portalapitest/import/robot_imports.php?customer=" + row.id} rel="noreferrer" target="_blank"><img src={imgBug} alt="" title={t("customer_change")}/></a> : null}
            </div>
          )
        },
      },
      this.addTextCell('customer', 'company_name', 0, (w && w['company_name'] ? w['company_name'] : 16)*fontSize, true, "", null, null, null, supportRight),
      this.addSelectCell('add_customer_country', 'country', this.state.countries, (w && w['country'] ? w['country'] : 8)*fontSize, true, null, null, false, subtraderRight),
      this.addTextAreaCell('address', 'company_address', (w && w['company_address'] ? w['company_address'] : 16)*fontSize, 2, true, null, null, null, denyServiceRight),
      this.addTextAreaCell('contact', 'contact', (w && w['contact'] ? w['contact'] : 12)*fontSize, 2, true, null, null, null, denyServiceRight),
      this.addTextCell('email', 'email', 0, (w && w['email'] ? w['email'] : 15)*fontSize, true, null, null, null, null, denyServiceRight),
      this.addTextCell('telephone', 'phone', 0, (w && w['phone'] ? w['phone'] : 10)*fontSize, subtraderRight),
      //this.addCheckboxCell('is_trader', 'is_trader', (w && w['is_trader'] ? w['is_trader'] : 5)*fontSize, subtraderRight, false, null, supportRight),
      { accessor: 'is_trader',
        Header: t('is_trader'),
        show: subtraderRight,
        width:  (w && w['is_trader'] ? w['is_trader'] : 5.8)*em,
        filterable: false,
        sortable: true,
        Cell: ({value: initialValue, column: { id }, row,}) => {
          const ID = parseInt(UserProfile.getCustomer())
          let img = imgFalse2
          if (row.is_trader === 1) img = imgTrue
          if (row.is_trader >= 2) img = imgImporter
          if (row.id !== ID && supportRight) return this.radioboxCell(initialValue, id , row, row.is_trader, [t("customer"), t("trader"), t("importer")])
          return <div width="100%" align="center"><img src={img} alt={t("enabled")} title={t("enabled")} /></div>
        },
      },
      //this.addCheckboxCell('enabled', 'enabled1', (w && w['enabled1'] ? w['enabled1'] : 5)*fontSize, subtraderRight, true),
      { accessor: 'enabled',
        Header: t('enabled'),
        width:  (w && w['enabled'] ? w['enabled'] : 5)*em,
        filterable: false,
        sortable: true,
        Cell: ({value: initialValue, column: { id }, row,}) => {
          const ID = parseInt(UserProfile.getCustomer())
          if (row.id !== ID) return this.radioboxCell(initialValue, id , row, row.enabled, ["false", "true"])
          return <div width="100%" align="center"><img src={imgTrue} alt={t("enabled")} title={t("enabled")} /></div>
        },
      },
      this.addTextCell('remote_name', 'remote_id', 0, (w && w['remote_id'] ? w['remote_id'] : 7.2)*fontSize, supportRight, "", null, this.getColor('cellAdmin')),
      this.addDateCell('customer_abo_time', 'abo_time', false, (w && w['abo_time'] ? w['abo_time'] : 7.2)*fontSize, true, "center", null, null, supportRight),
      this.addSelectCell("trader", "trader_id", this.state.trader, (w && w['trader_id'] ? w['trader_id'] : 12)*fontSize, traderRight, null, null, true, supportRight),
      { accessor: 'importer_id', show: false,},
      this.addSelectCell("customer_service", "support", this.state.service, (w && w['support'] ? w['support'] : 12)*fontSize, traderRight, null, null, true, supportRight),
      //this.addTextAreaCell('support', 'support', (w && w['support'] ? w['support'] : 12)*fontSize, 2, subtraderRight),
      this.addTextCell('§*Zeitzone', 'timezone', 0, (w && w['timezone'] ? w['timezone'] : 8)*fontSize, suRight, "", null, this.getColor('readOnly'), null, false),
      this.addTextCell('§Log Level', 'loglevel', 0, (w && w['loglevel'] ? w['loglevel'] : 4)*fontSize, suRight, "", 'center', this.getColor('cellAdmin')),
      { accessor: 'edit',
        Header: t('table_edit'),
        width: 7.5*em + (subtraderRight ? 3*em : 0),
        filterable: false,
        resizable: false,
        sortable: false,
        Cell: ({value: initialValue, column: { id }, row,}) => {
          const activate = value => {  console.log("sende Daten", row.id); this.activate1(row.id) }
          //const customerChange = value => { console.log("sende Customer", row.id);  this.setCustomer(row.id) }
          return (
            <div>
              {this.edit(row, false, suRight && row.enabled === 0, denyServiceRight)}
              {row.enabled === 0 ? <img src={imgActivate} alt="+" title={t("customer_activation")} onClick={activate}/> : null}
            </div>
          )
        },
      }
    ]
    return columns
  }
  dateToDays(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear()
    return parseInt(year)*10000+parseInt(month)*100+parseInt(day)
  }
  stringToDays(date) {
    if (date) {
      var year = date.substring(0, 4),
      month = date.substring(5, 7),
      day = date.substring(9, 10);
      //console.log(date, parseInt(year)*10000+parseInt(month)*100+parseInt(day))
      return parseInt(year)*10000+parseInt(month)*100+parseInt(day)
    }
    else return 30000000
  }
  setfilter(value) {
    //console.log(value)
    let filter1 = this.state.filterTrader
    //let filter2 = this.state.filterName
    if (value && value.name === "filter_customers") filter1 = value.value
    //if (value && value.name === "filter_name") filter2 = value.value
    let  a = []
    if (filter1 > 0) {
      // eslint-disable-next-line array-callback-return
      this.state.orgData.map((t,k) => { if (t.trader_id === parseInt(filter1) || t.id === -1 || t.importer_id === parseInt(filter1))  a = [...a, t] })
    }
    else a = this.state.orgData
    let  a1 = []
    /*if (filter2 !== "") {
      // eslint-disable-next-line array-callback-return
      a.map((t,k) => {
        if (t.login.toLowerCase().includes(filter2.toLowerCase()) || t.email.toLowerCase().includes(filter2.toLowerCase()) || t.org_id === -1) a1 = [...a1, t]
      })
    }
    else */
      a1 = a
    this.setState({
      data: a1,
      filterCustomer: filter1,
      //filterName: filter2,
      selected: null,
    })
  }
  render() {
    //console.log("Userlevel", UserProfile.getUserLevel(), UserProfile.getUserLevel() === "5")
    const columns = this.createColumns()
    //const suRight = UserProfile.checkRight("superuser")
    const supportRight = UserProfile.checkRight("support")
    //const traderRight =  UserProfile.checkRight("trader")
    const subtraderRight =  UserProfile.checkRight("addcust")
    const denyServiceRight = !UserProfile.checkRight("service") || subtraderRight
    let filter = false
    //if (this.state.data.length > 10) filter = true;
    const getDetails = value => { this.setState({ showHideEdit: true,}) }
    const changeColumnsWidth = (width, name) => {
      const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))*this.state.fontSize
      GetJsonFormPHP(this.state.setTableUrl + "?table=customer&col=" + name + "&width=" + width + "&em=" + em).then((result) => {
      })
    }
    const selectChange  = value => { console.log(value); this.setfilter(value) }
    //let fontSize = this.state.fontSize
    //if (traderRight) fontSize *= 0.75
    //console.log(window.sessionStorage.getItem("user_rights"))
    let today = new Date();
    let aboLeft14 = new Date();
    aboLeft14.setDate(aboLeft14.getDate() + 30);
    //console.log(this.dateToDays(aboLeft14))
    //console.log(this.stringToDays("2024-08-01"))
    const dot = (color = 'transparent') => ({
      alignItems: 'center',
      display: 'flex',
      ':before': {
        backgroundColor: color,
        borderRadius: 10,
        content: '" "',
        display: 'block',
        marginRight: 8,
        height: 10,
        width: 10,
      },
    })
    const colourStyles = {
      control: (styles) => ({ ...styles, height: 28, minHeight: 28, }),
      menuList: (provided, state) => ({ ...provided, paddingTop: 0, }),
      menu: base => ({ ...base, marginTop: 0, }),
      container: (base) => ({ ...base, display:'inline-block', }),
      indicatorContainer: (styles) => ({ ...styles, padding: 0, margin: 0, }),
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        const color = data.color
        return {
          ...styles,
          backgroundColor: isDisabled ? undefined
            : isFocused ? color //  color.alpha(0.1).css()
            : isSelected ? color //data.color
            : undefined,
          color: isDisabled ? '#ccc'
            : isFocused  ? 'white'
            : isSelected ? 'white'
            : data.color,
          cursor: isDisabled ? 'not-allowed' : 'default',
          ':active': {
            ...styles[':active'],
            backgroundColor: !isDisabled ? isSelected ? data.color : color  //color.alpha(0.3).css()
              : undefined,
          },
          paddingTop: 2,
          paddingBottom: 2,
        };
      },
      input: (styles) => ({ ...styles, ...dot() }),
      //placeholder: (styles) => ({ ...styles, ...dot('#ccc') }),
      singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
    }
    //console.log(this.state.trader)
    return (
      <div>
        <div className="table_buttons">
          {subtraderRight ? <button onClick={() => {getDetails()}}>{t("add_new_customer")}</button> : null }
          {supportRight ? <div className="inline">{t('filter_customer')}:&nbsp;
          <Select
            options={this.state.trader}
            styles={colourStyles}
            isSearchable={true}
            isClearable={true}
            className="chartselect"
            onChange={(value) => {selectChange({name: "filter_customers", value: value !== null ? value.value : 0})}}
            placeholder={t('select')}
          />
          </div>: null}
          <div className="right_side"><img id="info" src={require("./img/info.png")} alt="Info" onClick={() => { this.setState({ showHelp: true, showHelpId: "help_customers_table", }) } } onMouseOver={(e) => {e.currentTarget.src = require("./img/info_mouseover.png")}} onMouseOut={(e) => {e.currentTarget.src = require("./img/info.png")}} /></div>
        </div>
        <div className="inline"><h3>{t("customer_manuals")}: &nbsp;</h3></div><div className="inline"><br /><a href="https://connect.hetwin.at/public/uploads/docus/Kurzanleitung Hetwin connect_de.pdf" target="_blank" rel="noreferrer">{t("customer_short_docu")}</a><br /><br /></div>
        {this.state.tablePageSize > 0 ?
        <div style={{fontSize: this.state.fontSize+"em"}}>
        <ReactTable
          data={this.state.data}
          columns={columns}
          minRows = {0}
          previousText= {'<'}
          nextText= {'>'}
          showPageJump= {true}
          filterable = {filter}
          defaultPageSize = {this.state.tablePageSize}
          pageSizeOptions = {[10, 20, 50, 100]}
          useFlexLayout = {true}
          getTrProps={(state, rowInfo, column, instance) => {
            return {
              onDoubleClick: (e, t) => { if (rowInfo.index !== this.state.selected && denyServiceRight) { this.setState({ selected: rowInfo.index, }) } },
              style: {
                background: rowInfo && rowInfo.index === this.state.selected ? 'rgba(234, 234, 255, 1)' : rowInfo.row.is_trader >= 2 ? '#EEEEAA' : rowInfo.row.company_name === "Testaccount" ? '#DDDDDD' : rowInfo.row.is_trader > 0  ? '#EEFFBB' : 'white',
                color: rowInfo && rowInfo.index === this.state.selected ? this.getColor('selected') : this.stringToDays(rowInfo.row.abo_time) < this.dateToDays(today)  ? '#DD0000' : this.stringToDays(rowInfo.row.abo_time) < this.dateToDays(aboLeft14)  ? '#EE8822' :  'black'
              }
            }
          }}
          getTheadTrProps={(state, column, instance) => {
            return {
              onClick:  (e) => { if (!UserProfile.isSafari()) changeColumnsWidth(e.target.offsetParent.style.width, e.target.offsetParent.innerText) },
            }
          }}
          getProps = {(state, column, instance) => {
            return {
              onChange:  (e) => { console.log(e.target.name); if (e.target.type !== "number" && e.target.type !== "text" && e.target.name === "") changeColumnsWidth(e.target.value, "_pageSize") },
            }
          }}
        />
        </div>
        : null}
        { UserProfile.getUserLevel() <= 2 ? <CustomerSettings apiData={this.state.apiData} /> : null}
        <AlertDialog  openDialog = {this.state.confirmVisible} title = {this.state.title} message = {this.state.message} choise = {this.state.choise} callback = {this.handleDelete}/>
        {this.state.showHideEdit && <AddCustomer languages={this.state.languages} countries={this.state.countries} closeDiv={this.closeNewCustomer} />}
        {this.state.showHelp && <Info id={this.state.showHelpId} closeDiv={() => { this.setState({ showHelp: false, }) }} reset={() => { this.setState({ showHelpId: null, }) }}/>}
      </div>
    )
  }
}
export default Customers;

/*
          defaultPageSize= {10}
          pageText= {false}
          previousText= {'<'}
          nextText= {'>'}
          showPageJump= {false}
          showPagination= {true}

          filterable = {true}
          groupBy = {"id"}
          selection = {"multiple"}


          selection = {"multiple"}
          wrap = {false}
          compact = {true}
          searchable = {true}
          striped = {true}
          highlight  = {true}
          paginationType = {"jump"}
          bordered = {true}
          borderless = {true}
          onClick = {"expand"}
          onClick = {function(rowInfo, colInfo) {
            // Only handle click events on the 'details' column
            if (colInfo.id !== 'details') {
              return
            }

            // Display an alert dialog with details for the row
            window.alert('Details for row ' + rowInfo.index + ':\\n' + JSON.stringify(rowInfo.row, null, 2))

            // Send the click event to Shiny, which will be available in input$show_details
            // Note that the row index starts at 0 in JavaScript, so we add 1
            //if (window.Shiny) {
            //  Shiny.setInputValue('show_details', { index: rowInfo.index + 1 }, { priority: 'event' })
            //}
          }}

*/